import React from 'react';

class Ztu extends React.Component {
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    return (
      <>
        <center>
          <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '107mm' }}>
            {this.props.certificate &&
              this.props.certificate.list.map((el, index) => {
                if (el.application.athleteCategoryTitleId === '9bdfec96-04ad-4c00-a005-5d91f18a3607'||
                  el.application.athleteCategoryTitle==='Заслужений мйстер спорту України/ЗМСУ') {
                  return (
                    <div
                      style={
                        (index + 1) % 3 === 0
                          ? { margin: '20px auto 0', pageBreakAfter: 'always' }
                          : { margin: '20px auto 0' }
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '20px',
                        }}
                      >
                        <div
                          data-hook="left-side"
                          style={{
                            display: 'inline-flex',
                            width: '85mm!important',
                            height: '60mm!important',
                            backgroundImage: "URL('/Res/background.png')",
                            backgroundSize: 'cover',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '0.1mm solid #cccccc',
                            order: 3,
                          }}
                        >
                          <div
                            style={{
                              verticalAlign: 'middle',
                              width: '83mm',
                              height: '58mm',
                              border: '0.1mm solid #2D2C2A',
                            }}
                          >
                            <table
                              width="100%"
                              height="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                            >
                              <tr style={{ height: '7%', fontSize: '1mm', border: '0' }}>
                              <td style={{padding:'0',borderBottom: 'none',fontSize: '3mm',color:'red', fontWeight: 'bold',textAlign: 'center',verticalAlign: 'bottom',
                                }} colspan="2">
                                    &nbsp;
                              </td>
                              </tr>
                              <tr style={{ height: '1em', border: '0' }}>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  rowspan="4"
                                  width="36%"
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    border: '1px solid black',
                                    padding: '0px',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '30mm!important',
                                      height: '40mm!important',
                                      backgroundImage: `URL(${el.application.photo?.url})`,
                                      backgroundSize: 'cover',
                                    }}
                                  ></div>
                                </td>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  width="51%"
                                  align="center"
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '4.1mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  Звання присвоєно
                                </td>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ height: '3em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '3.5mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                  align="center"
                                >
                                  <div style={{ margin: '-0.15em' }}>
                                    <strong>
                                      {/* &laquo;30&raquo; листопада 2022 року<br/> */}
                                      {new Date(el.assignDate).toLocaleString('uk-UA', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      })}
                                      <span style={{ fontSize: '1.1em' }}>
                                        наказ № {el.orderNumber}
                                      </span>
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '2em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ borderBottom: '0.4mm solid black',padding:'0',borderBottom: 'none'}}>
                                  &nbsp;
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '3em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'top',
                                    lineHeight: '1.1',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    letterSpacing: '0.07em',
                                    padding: '0',
                                  }}
                                >
                                  (Особистий підпис)
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: 'auto', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td  style={{padding:'0',borderBottom: 'none',fontSize: '4mm',color:'red', fontWeight: 'bold',textAlign: 'center',verticalAlign: 'middle',}}>{el.hasCopy?'Дублікат':''}</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="left"
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '2.4mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;М.П.
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '7%', border: '0', fontSize: '1mm' }}>
                                <td colspan="5" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div style={{ display: 'inline-flex', width: '5mm!important', order: 2 }}>
                          &nbsp;
                        </div>
                        <div
                          data-hook="right-side"
                          style={{
                            display: 'inline-flex',
                            width: '85mm!important',
                            height: '60mm!important',
                            backgroundImage: 'URL("/Res/background.png")',
                            backgroundSize: 'cover',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '0.1mm solid #cccccc',
                            order: 1,
                          }}
                        >
                          <div
                            style={{
                              verticalAlign: 'middle',
                              width: '83mm',
                              height: '58mm',
                              border: '0.1mm solid #2D2C2A',
                            }}
                          >
                            <table
                              width="100%"
                              height="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              style={{ padding: '0px' }}
                            >
                              <tr style={{ fontSize: '1mm', height: '4%', border: '0' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ border: '0', height: 'auto' }}>
                                <td width="3%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  width="18%"
                                  rowspan="3"
                                  align="center"
                                  valign="middle"
                                  style={{ padding: '0px' }}
                                >
                                  <img src="/Res/gerb1.png" alt=" " style={{ width: '11mm' }} />
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#493185',
                                    lineHeight: '1.15',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  <strong>
                                    МІНІСТЕРСТВО
                                    <br />
                                    МОЛОДІ ТА СПОРТУ УКРАЇНИ
                                  </strong>
                                </td>
                                <td width="3%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ fontSize: '1mm', height: '4%', border: '0' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ border: '0', height: '1.5em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#493185',
                                    lineHeight: '1',
                                    padding: '0',
                                    fontSize: '3.8mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                  }}
                                >
                                  <strong>
                                    ПОСВІДЧЕННЯ {!el.hasCopy && <>№{' '}
                                    <div
                                      style={{
                                        display: 'inline-block',
                                        borderBottom: '1px solid black',
                                        width: '30%',
                                      }}
                                    >
                                      {el.certificateNumber}
                                    </div></>}
                                  </strong>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.2em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    fontSize: '5.5mm',
                                    fontFamily: "'Times New Roman', Times, serif",
                                    marginBottom: '1em',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em' }}>
                                    <strong style={{ textTransform: 'uppercase' }}>
                                      {el.application.athleteLastName}
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.4em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    fontSize: '5.5mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em' }}>
                                    <strong>
                                      {el.application.athleteFirstName}{' '}
                                      {el.application.athleteSecondName}
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.1em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    color: '#493185',
                                    lineHeight: '0.8',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '0em' }}>
                                    <strong>Є ЗАСЛУЖЕНИМ МАЙСТРОМ СПОРТУ УКРАЇНИ</strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.1em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    letterSpacing: '-0.03em',
                                    fontSize: '4.6mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em', marginTop: '-0.1em' }}>
                                    <i>
                                      <strong>з спорту: {el.application.sportTitle}</strong>
                                    </i>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: 'auto' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: '0',
                                  height: 'auto',
                                  lineHeight: '1.1',
                                  fontSize: '3.5mm',
                                  fontFamily: '"Times New Roman", Times, serif',
                                }}
                              >
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td align="left" valign="middle" style={{ padding: '0px' }}>
                                  <strong>{el.signer2Position.split(' ')[0]}</strong>
                                </td>
                                <td align="right" valign="middle" style={{ padding: '0px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div>&nbsp;</div>
                                    <div style={{ textAlign: 'center' }}>
                                      <strong>{el.signer2Name}</strong>
                                      <br />
                                      <div
                                        style={{
                                          fontSize: '2.5mm',
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                        }}
                                      >
                                        М.П.
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '3%', fontSize: '1mm' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (
                  el.application.athleteCategoryTitleId === 'cb4b06a9-d396-4791-b646-4659a068a5df'
                  || el.application.athleteCategoryTitle==='Заслужений тренер України/ЗТУ'
                ) {
                  return (
                    <div
                      style={
                        (index + 1) % 3 === 0
                          ? { margin: '20px auto 0', pageBreakAfter: 'always' }
                          : { margin: '20px auto 0' }
                      }
                    >
                      <div
                        style={
                          index === 4
                            ? {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px',
                                pageBreakAfter: 'always',
                              }
                            : {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px',
                              }
                        }
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            width: '85mm!important',
                            height: '60mm!important',
                            backgroundImage: 'URL("/Res/background.png")',
                            backgroundSize: 'cover',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '0.1mm solid #cccccc',
                            order: 3,
                          }}
                        >
                          <div
                            style={{
                              verticalAlign: 'middle',
                              width: '83mm',
                              height: '58mm',
                              border: '0.1mm solid #2D2C2A',
                            }}
                          >
                            <table
                              width="100%"
                              height="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                            >
                              <tr style={{ height: '7%', fontSize: '1mm', border: '0' }}>
                                <td colspan="5" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ height: '1em', border: '0' }}>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  rowspan="4"
                                  width="36%"
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    border: '1px solid black',
                                    padding: '0px',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '30mm!important',
                                      height: '40mm!important',
                                      backgroundImage: `URL(${el.application.photo?.url})`,
                                      backgroundSize: 'cover',
                                    }}
                                  ></div>
                                </td>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  width="51%"
                                  align="center"
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '4.1mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  Звання присвоєно
                                </td>
                                <td width="4%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ height: '3em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '3.5mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                  align="center"
                                >
                                  <div style={{ margin: '-0.15em' }}>
                                    <strong>
                                      {/* &laquo;30&raquo; листопада 2022 року */}
                                      {new Date(el.assignDate).toLocaleString('uk-UA', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      })}
                                      <br />
                                      <span style={{ fontSize: '1.1em' }}>
                                        наказ № {el.orderNumber}
                                      </span>
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '2em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ borderBottom: '0.4mm solid black',padding:'0',borderBottom: 'none'}}>
                                  &nbsp;
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '3em', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'top',
                                    lineHeight: '1.1',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    letterSpacing: '0.07em',
                                    padding: '0',
                                  }}
                                >
                                  (Особистий підпис)
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: 'auto', border: '0' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td style={{padding:'0',borderBottom: 'none',fontSize: '4mm',color:'red', fontWeight: 'bold',textAlign: 'center',verticalAlign: 'middle',}}>{el.hasCopy?'Дублікат':''}</td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="left"
                                  style={{
                                    lineHeight: '1.1',
                                    fontSize: '2.4mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;М.П.
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ height: '7%', border: '0', fontSize: '1mm' }}>
                                <td colspan="5" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div style={{ display: 'inline-flex', width: '5mm!important', order: 2 }}>
                          &nbsp;
                        </div>
                        <div
                          style={{
                            display: 'inline-flex',
                            width: '85mm!important',
                            height: '60mm!important',
                            backgroundImage: 'URL("/Res/background.png")',
                            backgroundSize: 'cover',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '0.1mm solid #cccccc',
                            order: 1,
                          }}
                        >
                          <div
                            style={{
                              verticalAlign: 'middle',
                              width: '83mm',
                              height: '58mm',
                              border: '0.1mm solid #2D2C2A',
                            }}
                          >
                            <table
                              width="100%"
                              height="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              style={{ padding: '0px' }}
                            >
                              <tr style={{ fontSize: '1mm', height: '4%', border: '0' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ border: '0', height: 'auto' }}>
                                <td width="3%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                                <td
                                  width="18%"
                                  rowspan="3"
                                  align="center"
                                  valign="middle"
                                  style={{ padding: '0px' }}
                                >
                                  <img src="/Res/gerb1.png" alt=" " style={{ width: '11mm' }} />
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#493185',
                                    lineHeight: '1.15',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  <strong>
                                    МІНІСТЕРСТВО
                                    <br />
                                    МОЛОДІ ТА СПОРТУ УКРАЇНИ
                                  </strong>
                                </td>
                                <td width="3%" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ fontSize: '1mm', height: '4%', border: '0' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr style={{ border: '0', height: '1.5em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  align="center"
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#493185',
                                    lineHeight: '1',
                                    padding: '0',
                                    fontSize: '3.8mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                  }}
                                >
                                  <strong>
                                  ПОСВІДЧЕННЯ {!el.hasCopy && <>№{' '}
                                    <div
                                      style={{
                                        display: 'inline-block',
                                        borderBottom: '1px solid black',
                                        width: '30%',
                                      }}
                                    >
                                      {el.certificateNumber}
                                    </div></>}
                                  </strong>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.2em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    fontSize: '5.5mm',
                                    fontFamily: "'Times New Roman', Times, serif",
                                    marginBottom: '1em',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em' }}>
                                    <strong style={{ textTransform: 'uppercase' }}>
                                      {el.application.athleteLastName}
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.4em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    fontSize: '5.5mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em' }}>
                                    <strong>
                                      {el.application.athleteFirstName}{' '}
                                      {el.application.athleteSecondName}
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.1em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    color: '#493185',
                                    lineHeight: '0.8',
                                    fontSize: '3.3mm',
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '0em' }}>
                                    <strong>Є ЗАСЛУЖЕНИМ ТРЕНЕРОМ УКРАЇНИ</strong>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '1.1em' }}>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td
                                  colspan="2"
                                  align="center"
                                  style={{
                                    verticalAlign: 'bottom',
                                    borderBottom: '1px solid black',
                                    lineHeight: '1.1',
                                    letterSpacing: '-0.03em',
                                    fontSize: '4.6mm',
                                    fontFamily: '"Times New Roman", Times, serif',
                                    padding: '0',
                                  }}
                                >
                                  <div style={{ marginBottom: '-0.1em', marginTop: '-0.1em' }}>
                                    <i>
                                      <strong>
                                        з{' '}
                                        {el.application.sportTitleGenitive ||
                                          el.application.sportTitle}
                                      </strong>
                                    </i>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: 'auto' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: '0',
                                  height: 'auto',
                                  lineHeight: '1.1',
                                  fontSize: '3.5mm',
                                  fontFamily: '"Times New Roman", Times, serif',
                                }}
                              >
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                                <td align="left" valign="middle" style={{ padding: '0px' }}>
                                  <strong>
                                    {el.signer2Position?.split(' молоді та спорту України')[0]}
                                  </strong>
                                </td>
                                <td align="right" valign="middle" style={{ padding: '0px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div>&nbsp;</div>
                                    <div style={{ textAlign: 'center' }}>
                                      <strong>{el.signer2Name}</strong>
                                      <br />
                                      <div
                                        style={{
                                          fontSize: '2.5mm',
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                        }}
                                      >
                                        М.П.
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: '0px' }}>&nbsp;</td>
                              </tr>
                              <tr style={{ border: '0', height: '3%', fontSize: '1mm' }}>
                                <td colspan="4" style={{ padding: '0px' }}>
                                  &nbsp;
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </center>
      </>
    );
  }
}

export default Ztu;
