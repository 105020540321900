import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../components/Avatar/Avatar';
import { getApplicationsList } from '../../../redux/actions/applications';
import Form from '../../DirectoriesPage/Form/Form';
import {
  createOrganisation,
  getOrganisationsUnconfirmedList,
} from '../../../redux/actions/organisations';
import Work from './Work/Work';
import Done from './Done/Done';
import Order from './Order/Order';
import Archive from './Archive/Archive';
import Pagination from '../../../components/Pagination/Pagination';
import {
  selectCurrentOrganisation,
  selectOrganisations,
} from '../../../redux/selectors/organisations';
import { PAGE_SIZE_APPLICATIONS } from '../../../constants/const';
import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
} from '../../../redux/selectors/auth';
import ApplicationService from '../../../redux/services/application.service';
import CertificateService from '../../../redux/services/certificate.service';
import NewLoader from '../../../components/NewLoader';

const AuthApplicationsPage = () => {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState([]);
  const [filterForm, setFilterForm] = useState([]);
  const isAdmin = useSelector(selectIsAdmin);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);
  const organisations = useSelector(selectOrganisations);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const { certificate } = useSelector((state) => state);
  const { applications } = useSelector((state) => state);
  const [formActive, setFormActive] = useState(false);
  const [filter, setFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [alertCreate, setAlertCreate] = useState(false);
  const { fullList } = useSelector((state) => state);
  const [alertActive, setAlertActive] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchValue, setSearchValue] = useState(localStorage.getItem('applicationsSearch') || '');
  const [search, setSearch] = useState(localStorage.getItem('applicationsSearch') || '');
  const [data, setData] = useState([]);
  const [tab, setTab] = useState({
    work: true,
    done: false,
    arch: false,
    order: false,
  });
  const [subtab, setSubTab] = useState({
    all: true,
    confirmed: false,
    refused: false,
    department:false,
    curator:false,
    departmentSection:false,
  });
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const history = useHistory();
  const [form, setForm] = useState({});
  const [currentSort, setCurrentSort] = useState('asc');
  const [selected, setSelected] = useState('athleteName');
  const [prevSelected, setPrevSelected] = useState('athleteName');
  const [sort, setSort] = useState({ sortName: selected, sortValue: currentSort });
  const [selectForm, setSelectForm] = useState([]);
  const [selectId, setSelectId] = useState([]);
  const [selectDep, setSelectDep] = useState([]);
  const [selectTitle, setSelectTitle] = useState('');
  const [order, setOrderActive] = useState(false);
  const [printCer, setPrintCer] = useState(false);
  const [giveCer, setGiveCer] = useState(false);
  const [orderForm, setOrderForm] = useState({});
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);
  console.log('isHeadOrTrustedEmployee')
  console.log(isHeadOrTrustedEmployee)
  const setPrint = () => {
    setFilter(false);
    setPrintCer(true);
    setGiveCer(false);
    setFormActive(true);
  };

  const setGive = () => {
    setFilter(false);
    setPrintCer(false);
    setGiveCer(true);
    setFormActive(true);
  };

  const setPrintSave = () => {
    let arr = [[], [], []];
    let obj = [[], [], []];
    let fullArr = [];
    sessionStorage.setItem('selectDep', JSON.stringify(selectDep));
    selectDep.forEach((el, index) => {
      fullArr.push(el.id);
      for (let key in orderForm) {
        if (el.department == key[key.length - 1]) {
          switch (el.department) {
            case '0':
              arr[0].push(el.id);
              obj[0].push(orderForm[key]);
              break;
            case '1':
              arr[1].push(el.id);
              obj[1].push(orderForm[key]);
              break;
            case '2':
              arr[2].push(el.id);
              obj[2].push(orderForm[key]);
              break;
          }
        }
      }
    });
    let data = {
      applications: fullArr,
      data: {
        status: 21,
      },
    };
    ApplicationService.updateMultipleApplications(data).then((res) => {
      arr.forEach((el, index) => {
        if (el.length > 0) {
          let data2 = {
            applications: el,
            data: {
              signer2Name: '',
              signer2Position: '',
            },
          };
          fullList[8].forEach((elem) => {
            if (elem.id == obj[index][0]) {
              data2.data.signer2Name = elem.firstName[0] + '.' + elem.lastName.toUpperCase();
              data2.data.signer2Position = elem.position;
            }
          });
          CertificateService.updateMultipleCertificate(data2);
        }
        if (index === 2) {
          history.push('print/staticlist/res');
        }
      });
    });
  };

  const setGiveSave = () => {
    let data = {
      applications: selectId,
      data: {
        status: 22,
        comment: orderForm.recipientName,
      },
    };

    ApplicationService.updateMultipleApplications(data).then(() => {
      const data2 = {
        applications: selectId,
        data: {
          recipientName: orderForm.recipientName,
        },
      };
      CertificateService.updateMultipleCertificate(data2).then(() => {
        setFormActive(false);
        let obj = { ...tab };
        obj.done = false;
        obj.arch = true;
        setTab(obj);
        setGiveCer(false);
      });
    });
  };

  const filterSave = () => {
    setFormActive(false);
    setFilter(false);

    if (tab.work) {
      sessionStorage.setItem('work', JSON.stringify(filterForm));
    } else if (tab.done) {
      sessionStorage.setItem('done', JSON.stringify(filterForm));
    } else if (tab.arch) {
      sessionStorage.setItem('arch', JSON.stringify(filterForm));
    }

    if (!sessionStorage.arch) {
      sessionStorage.setItem('arch', JSON.stringify([]));
    }
    if (!sessionStorage.done) {
      sessionStorage.setItem('done', JSON.stringify([]));
    }
    if (!sessionStorage.work) {
      sessionStorage.setItem('work', JSON.stringify([]));
    }

    setReload(true);
    setCurrentPage(1);
  };

  const onSortChange = (event) => {
    let sort = 'asc';
    let field = event.target.id;
    setSelected(field);
    setCurrentPage(1);

    if (field !== prevSelected) {
      setPrevSelected(field);
      setCurrentSort('asc');
    } else if (selected === prevSelected) {
      if (currentSort === 'asc') {
        setCurrentSort('desc');
        sort = 'desc';
      } else if (currentSort === 'desc') {
        setCurrentSort('asc');
        sort = 'asc';
      }
    }
    setSort({ sortName: field, sortValue: sort });
  };

  const selectHandler = (event) => {
    let res = [];
    let dep = [...selectDep];
    let arr = [...selectForm];
    let id = [...selectId];
    if (event.target.checked) {
      dep.push({ id: event.target.name, department: event.target.slot });
      setSelectTitle(event.target.slot);
      arr.push(event.target.name);
      id.push(event.target.name);
    } else if (!event.target.checked) {
      if (arr.length === 1) {
        setSelectTitle('');
      }
      dep = dep.filter((e) => e.id !== event.target.name);
      arr = arr.filter((e) => e !== event.target.name);
      id = id.filter((e) => e !== event.target.name);
    }

    if (tab.order) {
      arr.forEach((element) => {
        let val = certificate.list.filter((e) => e.application.id === element);
        if (val && val.length > 0) {
          arr = arr.filter((e) => e !== element);
          let region;
          let achievements = '';
          if (fullList) {
            fullList.forEach((el) => {
              el.forEach((element) => {
                if (element.id === val[0].application.organisation.regionId) {
                  region = element.title;
                }
              });
            });
          }
          val[0].application.achievements.forEach((el) => {
            achievements += el.eventTitle + ' ' + el.eventResult + '; ';
          });

          let needRes = {
            Cпорт: val[0].application.sportTitle,
            "Прізвище, ім'я,по батькові":
              val[0].application.athleteLastName +
              ' ' +
              val[0].application.athleteFirstName +
              ' ' +
              val[0].application.athleteSecondName,
            'Область, місто': region,
            Відомство: val[0].application.organisation.shortName,
            "Прізвище, ім'я, по батькові тренера, який підготував спортсмена":
              val[0].application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase()) +
              ' ' +
              val[0].application.trainerFirstNameFirst[0] +
              '.' +
              val[0].application.trainerSecondNameFirst[0] +
              '.',
            'Підстава присвоєння (результат)': achievements,
          };
          res.push(needRes);
        }
      });
      if (res && res.length > 0) {
        arr = [...arr, ...res];
      }
    }
    setSelectDep(dep);
    setSelectId(id);
    setSelectForm(arr);
  };

  const getApplications = useCallback(
    async (arr, page) => {
      const params = [];
      if (arr && page && currentPage !== page) {
        setCurrentPage(page);
        params.push(arr);
        params.push(page);
      } else if (arr) {
        params.push(arr);
        params.push(currentPage);
      }
      setLoading(true);
      dispatch(getApplicationsList(...params)).then((res) => {
        setReady(true);
      });
    },
    [currentPage],
  );

  // const uploadExel =()=>{
  //     selectId.forEach(el=>{
  //         let data ={
  //             id:el,
  //             status:19,
  //         }
  //         dispatch(createHistory(data)).then(res=>{
  //             setTab({['order']:false})
  //             setTab({['work']:true})
  //             setTab({['order']:true})
  //         }).catch(e=>{
  //         })
  //     })

  // }

  const setOrder = () => {
    setFormActive(true);
    setFilter(false);
    setOrderActive(true);
  };
  // const setOrderSave = ()=>{

  //     let date = new Date().toLocaleString('uk-UA')
  //     selectForm.forEach(el=>{
  //          ;
  //         let data ={
  //             id:el,
  //             status:18,
  //             orderNumber:date
  //         }
  //         dispatch(createHistory(data)).then(res=>{
  //             getApplications();
  //             let cur = {...tab}
  //             cur.order = true;
  //             cur.work = false;
  //             setTab(cur);
  //         })
  // })
  // }
  const setOrderSave = () => {
    let date = new Date().toLocaleString('uk-UA');
    let data = {
      applications: selectForm,
      data: {
        status: 18,
        orderNumber: date,
      },
    };
    ApplicationService.updateMultipleApplications(data).then((res) => {
      getApplications();
      let cur = { ...tab };
      cur.order = true;
      cur.work = false;
      setTab(cur);
    });
  };

  const handleLabelClick = () => {
    setFilter(false);
    setForm({ type: 0 });
    setAlertCreate(true);
    setFormActive(true);
  };
  const clickHandler = (event) => {
    setCurrentPage(1);
    setSelectForm([]);
    setSelectId([]);
    setSelectDep([]);
    setSelectTitle('');
    setTab({ [event.target.accessKey]: true });
  };
  const clickSubHandler = (event) => {
    setCurrentPage(1);
    setSelectForm([]);
    setSelectId([]);
    setSelectDep([]);
    setSelectTitle('');
    setSubTab({ [event.target.accessKey]: true });
  };
  const saveAddChange = useCallback(async () => {
    await dispatch(createOrganisation(form))
      .then((response) => {
        if (response.errors && response.errors.length > 0) {
          setError(response.errors);
        } else {
          setFormActive(false);
        }
        getUnconfirmedOrganisation();
      })
      .catch(() => {});
    setReady(true);
  }, [form, error, setError]);

  const handleAlertActive = () => {
    setAlertActive(false);
  };
  const filterClick = () => {
    setPrintCer(false);
    setGiveCer(false);
    setOrderActive(false);
    setFilter(true);
    setFormActive(true);
  };

  const createClick = useCallback(async () => {
    const response = await ApplicationService.createApplication();
    if (response) {
      history.push(`/applications/${response.id}`);
    } else {
      setErrorMessage('Під час створення заявки сталася технічна помилка. Спробуйте ще раз.');
    }
  }, []);
  const searchHandler = (e) => {
    setCurrentPage(1);
    const { value } = e.target;
    setSearchValue(value);
    localStorage.setItem('applicationsSearch', e.target.value);
    if (value.length > 2) {
      setSearch(value);
    } else if (e.target.value.length === 0) {
      setSearch('');
    }
  };

  const getUnconfirmedOrganisation = useCallback(async () => {
    setReady(false);
    await dispatch(getOrganisationsUnconfirmedList()).then(() => {
      if (organisations && organisations.length > 0) {
        organisations.forEach((element) => {
          if (element.requestedBy === currentUserId) {
            setAlertActive(true);
          }
        });
      }
      setReady(true);
    });
  }, [ready, organisations, currentUserId]);

  useEffect(() => {
    if (!currentOrganisationId) {
      void getUnconfirmedOrganisation();
    }
    // getApplications()
    setReady(true);
  }, [currentPage]);

  // useEffect(()=>{
  //   if(loading){
  //     getApplications();
  //   }
  // },[]);


  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        {/* <button className="icon-text-btn-secondary docs">Звіти</button> */}
        {!tab.order && (
          <button
            className={
              filterForm && filterForm.length > 0
                ? 'icon-text-btn-secondary filter filter-on'
                : 'icon-text-btn-secondary filter'
            }
            onClick={filterClick}
          >
            Фільтр
          </button>
        )}
        <Avatar />
      </div>

      <h1 className="public-header">
        Заявки для присвоєння спортивних звань {!!applications.total && `(${applications.total})`}
      </h1>
      <label className="search" style={{ marginBottom: '30px' }}>
        {!tab.order && (
          <div className="btn">
            <input
              className="search-input"
              type="text"
              onChange={searchHandler}
              value={searchValue}
              placeholder="Пошук (мінімум 3 символа)"
            />
            <i className="icon search-icon"></i>
          </div>
        )}
      </label>
      {currentOrganisation && currentOrganisation.type === 0 && (
        <button className="create-app icon-text-btn-primary btn-primary" onClick={createClick}>
          Створити заявку
        </button>
      )}

      <div className="auth-tab">
        <button
          accessKey="work"
          className={tab.work ? 'auth-tab-item selected' : 'auth-tab-item'}
          onClick={clickHandler}
        >
          {isAdmin ? 'Заявки' : 'Мені на розгляд'}
        </button>
        {currentOrganisation && currentOrganisation.type === 10 && (
          <button
            accessKey="order"
            className={tab.order ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickHandler}
          >
            Накази
          </button>
        )}
        {currentOrganisation && currentOrganisation.type === 10 ? (
          <button
            accessKey="done"
            className={tab.done ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickHandler}
          >
            Посвідчення
          </button>
        ) : (
          <button
            accessKey="done"
            className={tab.done ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickHandler}
          >
            {isAdmin ? 'Чернетки' : 'В опрацюванні'}
          </button>
        )}
        {!isAdmin && (
          <button
            accessKey="arch"
            className={tab.arch ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickHandler}
          >
            Розгляд завершено
          </button>
        )}
      </div>
      <div> </div>
      {tab?.arch && (
        <div className="auth-subtab">
          <button
            accessKey="all"
            className={subtab.all ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Всі заявки
          </button>
          <button
            accessKey="confirmed"
            className={subtab.confirmed ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Тільки погоджені
          </button>
          <button
            accessKey="refused"
            className={subtab.refused ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Тільки відхилені
          </button>
        </div>
      )}
      {tab.work && currentOrganisation?.type=='2' && (
        <div className="auth-subtab">
          <button
            accessKey="all"
            className={subtab.all|| !subtab ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Всі заявки
          </button>
          <button
            accessKey="department"
            className={subtab.department ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Олімпійські
          </button>
          <button
            accessKey="curator"
            className={subtab.curator ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Неолімпійські
          </button>
        </div>
      )}
      {tab.work && currentOrganisation?.type=='5' && (
        <div className="auth-subtab">
          <button
            accessKey="all"
            className={subtab.all|| !subtab ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Мої заявки
          </button>
          {isHeadOrTrustedEmployee && <button
            accessKey="department"
            className={subtab.department ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            У відділі
          </button>}
          <button
            accessKey="curator"
            className={subtab.curator ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            У куратора
          </button>
        </div>
      )}
      {tab.work && (currentOrganisation?.type=='7'|| currentOrganisation?.type=='8' || currentOrganisation?.type=='9')&& 
       <div className="auth-subtab">
        { !(currentOrganisation?.type=='3' && !isHeadOrTrustedEmployee) && <button
          accessKey="all"
          className={subtab.all|| !subtab ? 'auth-tab-item selected' : 'auth-tab-item'}
          onClick={clickSubHandler}
        >
          Мої заявки
        </button>}
        {userData.sports.length>0 && <button
          accessKey="allMy"
          className={subtab.allMy  ? 'auth-tab-item selected' : 'auth-tab-item'}
          onClick={clickSubHandler}
        >
          Заявки могу відділу
        </button>}
        </div>
      }
      {tab.work && (currentOrganisation?.type=='3'|| currentOrganisation?.type=='4'|| currentOrganisation?.type=='6') && (
        <div className="auth-subtab">
          { !(currentOrganisation?.type=='3' && !isHeadOrTrustedEmployee) && <button
            accessKey="all"
            className={subtab.all|| !subtab ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Мої заявки
          </button>}
          { currentOrganisation?.type=='6' && !isHeadOrTrustedEmployee && userData.sports.length>0&& <button
            accessKey="allMy"
            className={subtab.allMy  ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Заявки могу відділу
          </button>}
          {isHeadOrTrustedEmployee && (currentOrganisation?.type=='3'|| currentOrganisation?.type=='4') && <button
            accessKey="department"
            className={subtab.department ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            У керівника відділу
          </button>}
          {isHeadOrTrustedEmployee && (currentOrganisation?.type=='3' || currentOrganisation?.type=='4' || currentOrganisation?.type=='6' )&& <button
            accessKey="departmentSection"
            className={subtab.departmentSection ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            У відділі
          </button>}
          <button
            accessKey="curator"
            className={subtab.curator ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            У тренера (куратора)
          </button>
        </div>
      )}
      <div className="public-table" style={{ overflowX: 'auto' }}>
        {selectForm && selectForm.length > 0 && tab.work && (
          <button
            className="icon-text-btn-secondary"
            onClick={setOrderSave}
            style={{ marginBottom: '25px' }}
          >
            Сформувати наказ
          </button>
        )}
        {selectForm && selectForm.length > 0 && tab.done && (
          <button
            className="icon-text-btn-secondary print"
            onClick={setPrint}
            style={{ marginBottom: '25px' }}
          >
            Друкувати посвідчення
          </button>
        )}
        {selectForm && selectForm.length > 0 && tab.done && (
          <button
            className="icon-text-btn-secondary"
            onClick={setGive}
            style={{ marginBottom: '25px' }}
          >
            Видати посвідчення
          </button>
        )}
        {/* {selectId && selectId.length>0 && tab.order &&   <ExportCSVWord uploadExel={uploadExel} csvData={selectForm} setSelectId={setSelectId} setSelectForm={setSelectForm}  fileName='Export' />} */}
        {/* {selectId && selectId.length>0 && tab.order &&   <ExportCSV uploadExel={uploadExel} csvData={selectForm}  setSelectId={setSelectId} setSelectForm={setSelectForm}   fileName='Export'/>} */}

        <table className="public-table-cust">
          <thead>
            <tr>
              <th> </th>
              <th
                onClick={onSortChange}
                className={selected === 'datePublished' ? `selected ${currentSort}` : ''}
                id="datePublished"
                width="75"
              >
                <span id="datePublished">Дата</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteCategoryTitle' ? `selected ${currentSort}` : ''}
                id="athleteCategoryTitle"
                width="56"
              >
                <span id="athleteCategoryTitle">Звання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'sportTitle' ? `selected ${currentSort}` : ''}
                id="sportTitle"
                width="170"
              >
                <span id="sportTitle">Вид спорту</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteName' ? `selected ${currentSort}` : ''}
                id="athleteName"
                style={{ minWidth: '280px' }}
              >
                <span className="two-rows" id="athleteName">
                  ПІБ спортсмена/тренера <br /> Поточний етап заявки, дні в обробці
                </span>
              </th>
              <th>
                Стать
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'sportCategoryType' ? `selected ${currentSort}` : ''}
                id="sportCategoryType"
              >
                <span id="sportCategoryType">Департамент</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationName' ? `selected ${currentSort}` : ''}
                id="organisationName"
              >
                <span id="organisationName">Суб’єкт подання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'company' ? `selected ${currentSort}` : ''}
                id="company"
              >
                <span id="company">Відомство</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationRegion' ? `selected ${currentSort}` : ''}
                id="organisationRegion"
              >
                <span id="organisationRegion">Область, місто</span>
              </th>
            </tr>
          </thead>
          {
            <tbody>
              {tab.work && (
                <Work
                  subtab={subtab}
                  getApplications={getApplications}
                  type="work"
                  selectHandler={selectHandler}
                  reload={reload}
                  setReload={setReload}
                  setFilterForm={setFilterForm}
                  search={search}
                  sort={sort}
                  setData={setData}
                  selectTitle={selectTitle}
                  currentPage={currentPage}
                  selectForm={selectForm}
                  selectId={selectId}
                />
              )}
              {tab.order && (
                <Order
                  getApplications={getApplications}
                  type="order"
                  selectHandler={selectHandler}
                  reload={reload}
                  setReload={setReload}
                  setFilterForm={setFilterForm}
                  search={search}
                  sort={sort}
                  setData={setData}
                />
              )}
              {tab.done && (
                <Done
                  getApplications={getApplications}
                  type="done"
                  selectHandler={selectHandler}
                  reload={reload}
                  setReload={setReload}
                  setFilterForm={setFilterForm}
                  search={search}
                  sort={sort}
                  setData={setData}
                  currentPage={currentPage}
                />
              )}
              {tab.arch && (
                <Archive
                  subtab={subtab}
                  getApplications={getApplications}
                  type="arch"
                  selectHandler={selectHandler}
                  reload={reload}
                  setReload={setReload}
                  setFilterForm={setFilterForm}
                  search={search}
                  sort={sort}
                  setData={setData}
                  currentPage={currentPage}
                />
              )}
              {/*loading && (
                <tr className="public-table-overlay"><td colSpan={8}>Завантаження...</td></tr>
              )*/}
            </tbody>
          }
          {!tab.order && applications && applications.total > PAGE_SIZE_APPLICATIONS && (
            <tfoot className="">
              <tr>
                <td colSpan="8">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={applications.total}
                    pageSize={PAGE_SIZE_APPLICATIONS}
                    onPageChange={async (page) => {
                      setReady(false);
                      await setCurrentPage(page);
                    }}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {!currentOrganisation && !isAdmin && (
        <>
          <div className="alert-label">
            <div>Для обробки заявок вам необхідно зареєструвати свою організацію.</div>
            <button className="icon-text-btn-secondary" onClick={handleLabelClick}>
              <i className="add-icon"></i>Зареєструвати&nbsp;організацію
            </button>
          </div>
          {formActive && (
            <Form
              form={form}
              saveAddChange={saveAddChange}
              setForm={setForm}
              setFormActive={setFormActive}
              error={error}
              setError={setError}
              alertCreate={alertCreate}
            />
          )}
        </>
      )}
      {alertActive && !!organisations.length && !isAdmin && (
        <>
          <div className="alert-label">
            <div>
              Дані про організацію відправлено на модерацію. Після перевірки вам буде надано доступ
              до повного функціоналу.
            </div>
            <button className="round-btn-secondary close" onClick={handleAlertActive}></button>
          </div>
        </>
      )}
      {errorMessage && (
        <>
          <div className="alert-label">
            <div>{errorMessage}</div>
            <button
              className="round-btn-secondary close"
              onClick={() => setErrorMessage(null)}
            ></button>
          </div>
        </>
      )}
      {formActive && filter && (
        <Form
          form={filterForm}
          saveAddChange={filterSave}
          filter={filter}
          setForm={setFilterForm}
          filterApp={true}
          setFormActive={setFormActive}
        />
      )}
      {formActive && printCer && (
        <Form
          form={orderForm}
          saveAddChange={setPrintSave}
          printCer={printCer}
          setForm={setOrderForm}
          selectDep={selectDep}
          setFormActive={setFormActive}
          selectId={selectId}
        />
      )}
      {formActive && giveCer && (
        <Form
          form={orderForm}
          saveAddChange={setGiveSave}
          giveCer={giveCer}
          setForm={setOrderForm}
          setFormActive={setFormActive}
        />
      )}
    </div>
  );
};

export default AuthApplicationsPage;
