import React from 'react';

class Order1 extends React.Component {

  constructor(props) {
    super(props);
    this.orderRef = React.createRef();
 }
  getInnerHTML = () => {
    return this.orderRef.current ? this.orderRef.current.innerHTML : '';
  };

  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    let categoryTitle = this.props.sportCategoryTitles[this.props.index - 1];

    const certificates = (this.props.certificate && this.props.certificate.list) || [];

    let categoryTitleGenitive = '';
    switch (categoryTitle) {
      case 'Неолімпійський':
        categoryTitleGenitive = 'неолімпійських видів спорту';
        break;
      case 'Олімпійський':
        categoryTitleGenitive = 'олімпійських видів спорту';
        break;
      default:
        categoryTitleGenitive = 'видів спорту осіб з інвалідністю';
    }

    certificates.sort(function (a, b) {
      if (a.application.sportTitle > b.application.sportTitle) {
        return 1;
      }
      if (a.application.sportTitle < b.application.sportTitle) {
        return -1;
      }
      if (a.application.athleteLastName > b.application.athleteLastName) {
        return 1;
      }
      if (a.application.athleteLastName < b.application.athleteLastName) {
        return -1;
      }
      return 0;
    });

    return (
      <div
        ref={this.orderRef} // Attach ref here
        style={{
          fontSize: '20px',
          fontFamily: '"Times New Roman"',
          orientation: 'landscape',
          gridArea: 'article',
          width: '1400px',
        }}
      >
        <div style={{ padding: '100px 5% 25mm 5%', fontFamily: '"Times New Roman"' }}>
          <div style={{textAlign:'end'}}>
            <div style={{ margin: '0 0 40px 70%', lineHeight:'24px', textAlign:'left' }}>
              Додаток {this.props.sportCategoryTitles.length > 1 && this.props.index} 
              <br />
              <br />
              до наказу Мінмолодьспорту <br />
              "Про присвоєння спортивного звання <br />"
              {certificates[0] && certificates[0].application.athleteCategoryTitle.split('/')[0]}"
            </div>
          </div>
          <div style={{ textAlign: 'center',fontWeight:'bold' }}>Список</div>
          <div style={{ textAlign: 'center', marginBottom: '20px',fontWeight:'bold' }}>
            спортсменів, яким присвоєно спортивне звання "
            
            {certificates[0] && certificates[0].application.athleteCategoryTitle.split('/')[0]}" <br/> з{' '}
            
            {categoryTitleGenitive}{' '}
          </div>
          <table>
            <tbody>
              <tr 
                // height="80px"
                >
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  № заявки
                </td>
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  Прізвище, ім'я, по батькові
                </td>
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  Область, місто
                </td>
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  Відомство
                </td>
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  Власне ім'я, прізвище  тренера, який підготував спортсмена

                  <br style={{fontSize:'14px'}}/>
          
                </td>
                <td
                  // height="80px"
                  style={{ border: '1px solid black', verticalAlign: 'top', textAlign: 'center' }}
                >
                  Підстава присвоєння <br />
                  (результат)
                </td>
              </tr>
              <tr 
              
              >
                <td style={{ border: '1px solid black', textAlign: 'center' }}></td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>1</td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>2</td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>3</td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>4</td>
                <td style={{ border: '1px solid black', textAlign: 'center' }}>5</td>
              </tr>
              {certificates &&
                certificates.map((certificate, index) => {
                  let region;
                  let company;
                  let achievements = '';

                  if (this.props.fullList) {
                    this.props.fullList.forEach((el) => {
                      el.forEach((element) => {
                        if (element.id === certificate.application.regionId) {
                          region = element.title;
                        }else if(element.id==certificate.application.company){
                          company= element.title;
                        }
                      });
                    });
                  }
                  certificate.application.achievements.forEach((el) => {
                    achievements += el.eventTitle + ' ' + el.eventResult + '\n';
                  });

                  if (certificate.application.sportCategoryTitle === categoryTitle || (certificate.application.sportCategoryTitle.includes("Види спорту осіб з інвалідністю") && categoryTitle.includes("Види спорту осіб з інвалідністю"))) {
                    return (
                      <React.Fragment key={certificate.application.id}>
                        {!(
                          certificates[index - 1] &&
                          certificates[index - 1].application.sportTitle ===
                            certificate.application.sportTitle
                        ) && (
                          <tr
                            style={{
                              fontWeight: 'bold',
                              border: '1px solid black',
                              // height: 'auto',
                            }}
                          >
                            <td
                              style={{
                                fontWeight: 'bold',
                                border: '1px solid black',
                                // height: 'auto',
                              }}
                              colSpan={6}
                            >
                              {certificate.application.sportTitle} 
                              {certificate.application.sportCategoryTitle.includes("Види спорту осіб з інвалідністю") && 
                                  (() => {
                                      const suffix = certificate.application.sportCategoryTitle.split("Види спорту осіб з інвалідністю")[1]?.trim();
                                      return suffix 
                                          ? `(${suffix === "з ураженнями опорно-рухового апарату" ? "з ураженнями ОРА" : suffix})`
                                          : '';
                                  })()} 
                            </td>
                          </tr>
                        )}
                        {index === 5 ? (
                          <tr style={{ 
                            // height: 'auto',
                             marginBottom: '600px' }}>
                             <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.officeCode.slice(0, 5)}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.athleteLastName.toUpperCase()}
                              <br />
                              {certificate.application.athleteFirstName +
                                ' ' +
                                certificate.application.athleteSecondName}{' '}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {region}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {company}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase()) +
                                ' ' +
                                certificate.application.trainerFirstNameFirst[0] +
                                '.'}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.achievements.map((el) => {
                                return (
                                  <React.Fragment key={el.eventTitle}>
                                    {' '}
                                    {el.eventTitle} {el.eventResult} <br />{' '}
                                  </React.Fragment>
                                );
                              })}
                            </td>
                          </tr>
                        ) : (
                          <tr 
                          // style={{ height: 'auto' }}
                          >
                             <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.officeCode.slice(0, 5)}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.athleteLastName.toUpperCase()}
                              <br />
                              {certificate.application.athleteFirstName +
                                ' ' +
                                certificate.application.athleteSecondName}{' '}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {region}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {company}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase()) +
                                ' ' +
                                certificate.application.trainerFirstNameFirst[0] +
                                '.' +
                                certificate.application.trainerSecondNameFirst[0] +
                                '.'}
                            </td>
                            <td style={{ border: '1px solid black', textAlign: 'left' }}>
                              {certificate.application.achievements.map((el) => {
                                return (
                                  <React.Fragment key={el.eventTitle}>
                                    {' '}
                                    {el.eventTitle} {el.eventResult} <br />{' '}
                                  </React.Fragment>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Order1;
