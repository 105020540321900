import {
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    ImageRun,
  } from "docx";
  
  export class DocumentCreator {
    async create([certificate, fullList]) {
      const columnWidth = TabStopPosition.MAX / 2;
      const receiptTabStops = [
        { type: TabStopType.LEFT, position: columnWidth - 180 },
        { type: TabStopType.RIGHT, position: TabStopPosition.MAX },
      ];
  
      const arr = certificate.list;
      const newArr = [];
  
      arr.forEach((el) => {
        if (!newArr.includes(el.application.sportCategoryTitle)) {
          newArr.push(el.application.sportCategoryTitle);
        }
      });
      newArr.sort((a, b) => b.localeCompare(a, "ru"));
  
      const renderedCategories = new Set();
      const imageUrl = "https://title.mms.gov.ua/Res/Gerb.png";
      const imageData = await loadImage(imageUrl);

      async function loadImage(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
    }

  
      const document = new Document({
        sections: [
          {
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER, // Выравнивание по центру
                    children: [
                        new ImageRun({
                            data: imageData, // Используем ArrayBuffer
                            transformation: {
                                width: 100, // ширина изображения (в пикселях)
                                height: 140, // высота изображения (в пикселях)
                            },
                        }),
                    ],
                }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "МІНІСТЕРСТВО МОЛОДІ ТА СПОРТУ УКРАЇНИ",
                    size: 28,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: {
                  after: 280,
                  before: 280,
                },
                children: [
                  new TextRun({
                    text: "Н А К А З",
                    size: 32,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                tabStops: receiptTabStops,
                children: [
                  new TextRun({
                    text: "від ____________ 20__р.\tКиїв\t№ __________",
                    size: 26,
                  }),
                ],
                spacing: {
                  before: 140,
                  after: 840,
                },
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                  before: 840,
                },
                children: [
                  new TextRun({
                    text: "Про присвоєння спортивного звання",
                    size: 28,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 840,
                },
                children: [
                  new TextRun({
                    text: '"Майстер спорту України"',
                    size: 28,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.BOTH,
                indent: {
                  firstLine: 800,
                },
                spacing: {
                  after: 280,
                },
                children: [
                  new TextRun({
                    text:
                      "Відповідно до вимог Положення про Єдину спортивну класифікацію України, затвердженого наказом Мінмолодьспорту від 07.12.2023 № 7512, зареєстрованого в Міністерстві юстиції України 25 грудня 2023 р. за\u00A0№\u00A02240/41296\u00A0(зі\u00A0змінами),",
                    size: 28,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                  before: 280,
                  after: 280,
                },
                children: [
                  new TextRun({
                    text: "НАКАЗУЮ:",
                    size: 28,
                    bold: true,
                  }),
                ],
              }),
              ...newArr.map((el, index) => {
                if (renderedCategories.has(el)) {
                  return null;
                }
  
                renderedCategories.add(el);
  
                let res = "";
                switch (el) {
                  case "Неолімпійський":
                    res = "неолімпійських видів спорту";
                    break;
                  case "Олімпійський":
                    res = "олімпійських видів спорту";
                    break;
                  default:
                    res = "видів спорту осіб з інвалідністю";
                    break;
                }
  
                const athletesTitle =
                  certificate.list[0].application.athleteCategoryTitle.split(
                    "/"
                  )[0];
  
                return new Paragraph({
                  alignment: AlignmentType.JUSTIFIED,
                  indent: { firstLine: 800 },
                  spacing: {
                    after: 400,
                  },
                  children: [
                    new TextRun({
                      text: `${index + 1}. Присвоїти спортивне звання "${athletesTitle}" спортсменам з ${res} згідно зі списком ${
                        newArr.length === 1
                          ? ", що додається."
                          : `(додаток ${index + 1}).`
                      }`,
                      size: 28,
                    }),
                  ],
                });
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                  before: 280,
                  after: 840,
                },
                indent: { firstLine: 800 },
                children: [
                  new TextRun({
                    text: `${newArr.length+1}. Контроль за виконанням цього наказу залишаю за собою.`,
                    size: 28,
                  }),
                ],
              }),
              new Paragraph({
                tabStops: receiptTabStops,
                size: 28,
                children: [
                  new TextRun({
                    text: `${certificate.list[0].signer1Position}\t\t${certificate.list[0].signer1Name}`,
                    size: 28,
                  }),
                ],
              }),
            ],
          },
        ],
      });
  
      return document;
    }
  }
  